<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="12" style="overflow-x: auto;">
                <zw-table ref="table"
                          tableName="customer_templates"
                          base-table="customer_templates"
                          columnsPrefix="customer.templates.column."
                          :items="myProvider"
                          tableFormat="App\Managers\Format\CustomerTemplatesFormat"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="customer.templates.title"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >
                    <template #cell(actions)="row">
                        <b-button class="mr-1" size="sm" variant="success"
                                  @click="$root.copyToClipboard(row.item.text)">
                            <font-awesome-icon icon="copy"/>
                        </b-button>
                        <b-button class="mr-1" size="sm" variant="success" @click="createProtocol(row)">
                            <font-awesome-icon icon="copy"/>
                            + Protocol
                        </b-button>
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('customer-template', {'id': row.item.id}, refreshTable)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>

                            <b-dropdown-item
                                @click="$root.copyToClipboard(row.item.text)">
                                <font-awesome-icon class="mr-2" icon="copy"/>
                                {{ $t('common.title.copy') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";

export default {
    name: 'customerClients',
    components: {ZwModelSelectorGroup},
    mixins: [commonTable],
    props: {
        customerId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            saveFilters: true,
            tableUniqKey: this.customerId
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('CommonTable', ['getCommonTable']),
        ...mapGetters('Customer', ['getCustomer']),
        shown() {
            this.$store.dispatch('Customer/fetchCustomer', this.customerId)
                .finally(() => {
                    this.loading = false;
                })
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'CommonTable/fetchTableData', 'getCommonTable');
        },
        ctxAdditionalParams() {
            return {
                'customer_id': this.customerId,
            }
        },
        ctxBaseParams() {
            return {
                'url': '/table-data/customer_templates',
                'format': 'App\\Managers\\Format\\CustomerTemplatesFormat'
            }
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('customer-template', {'id': 0}, this.refreshTable)
                    }
                }
            ]
        },
        createProtocol(row) {
            this.$root.copyToClipboard(row.item.text)

            axios.post(window.apiUrl + '/events/create', {
                action: 'WhatsApp',
                description: row.item.text,
                id: this.customerId,
                type: 'customer',
            })
                .then(result => {
                    console.log(this.getCustomer(), this.getCustomer().mobile)
                    if(this.getCustomer().mobile) {
                        console.log('ok')
                        window.open('https://wa.me/'+this.getCustomer().mobile+'?text='+encodeURIComponent(row.item.text), '_blank').focus();
                    }
                }, () => {
                    this.loading = false
                })
        }
    }
}
</script>